export default {
  root: {
    class: [
      'relative',

      // Alignment
      'inline-flex',
      'align-bottom',

      // Size
      'w-6',
      'h-6',
      'mr-1',

      // Misc
      'cursor-pointer',
      'select-none',
    ],
  },
  box: ({ context }) => ({
    class: [
      'flex items-center justify-center border-2 w-6 h-6 text-gray-600 rounded-lg transition-colors duration-200 hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
      { 'border-blue-500 bg-blue-500': context.checked },
      { 'border-neutral-200 bg-neutral-100': context.disabled },
    ],
  }),
  input: {
    class: [
      'peer',

      // Size
      'w-full ',
      'h-full',

      // Position
      'absolute',
      'top-0 left-0',
      'z-10',

      // Spacing
      'p-0',
      'm-0',

      // Shape
      'opacity-0',
      'rounded',
      'outline-none',
      'border border-slate-300',

      // Misc
      'appearance-none',
      'cursor-pointer',
    ],
  },
  icon: () => ({
    class: 'w-4 h-4 transition-all duration-200 text-white text-base',
  }),
};
